import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';

function BleezBillsTable({ data }) {
  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 385px)">
      <TableContainer component={Paper} style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell>Object</TableCell>
              <TableCell>Stamp</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Complementary Label</TableCell>
              <TableCell>Bill</TableCell>
              <TableCell>Price A</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.account_number}</TableCell>
                <TableCell>{row.object}</TableCell>
                <TableCell>{row.stamp}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.complementary_label}</TableCell>
                <TableCell>{row.bill}</TableCell>
                <TableCell>{row.price_a}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

BleezBillsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      account_number: PropTypes.string,
      object: PropTypes.string,
      stamp: PropTypes.string,
      code: PropTypes.string,
      complementary_label: PropTypes.string,
      bill: PropTypes.string,
      price_a: PropTypes.number
    })
  ).isRequired
};

export default BleezBillsTable;
