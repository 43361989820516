import React from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import BleezUsers from './BleezUsers';
import PronotePerisco from './PronotePerisco';

function App() {
  const [activeTab, setActiveTab] = React.useState('bleez-users');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Pronote Exporter
      </Typography>
      <Tabs value={activeTab} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Utilisateurs" value="bleez-users" />
        <Tab label="Periscolaire" value="pronote-perisco" />
      </Tabs>
      <Box mt={3}>
        {activeTab === 'bleez-users' && <BleezUsers />}
        {activeTab === 'pronote-perisco' && <PronotePerisco />}
      </Box>
    </Container>
  );
}

export default App;
