import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';

function PronotePersicoTable({ data }) {
  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 385px)">
      <TableContainer component={Paper} style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Classe</TableCell>
              <TableCell>Élève</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Inscriptions</TableCell>
              <TableCell>Présences</TableCell>
              <TableCell>Présences sans inscription</TableCell>
              <TableCell>Absences</TableCell>
              <TableCell>Annulations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell>{row.student_class}</TableCell>
                  <TableCell>
                    {row.student_name} {row.student_firstname}
                  </TableCell>
                  <TableCell>{row.service}</TableCell>
                  <TableCell>{row.nb_registration}</TableCell>
                  <TableCell>{row.nb_presence}</TableCell>
                  <TableCell>{row.nb_presence_without_registration}</TableCell>
                  <TableCell>{row.nb_absence}</TableCell>
                  <TableCell>{row.nb_cancellation}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

PronotePersicoTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      student_class: PropTypes.string,
      student_name: PropTypes.string,
      student_firstname: PropTypes.string,
      service: PropTypes.string,
      nb_registration: PropTypes.number,
      nb_presence: PropTypes.number,
      nb_presence_without_registration: PropTypes.number,
      nb_absence: PropTypes.number,
      nb_cancellation: PropTypes.number
    })
  ).isRequired
};
export default PronotePersicoTable;
