import React, { useState } from 'react';
import { Alert, Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import PronotePeriscoTable from './PronotePeriscoTable';
import BleezBillsTable from './BleezBillsTable';

function PronotePerisco() {
  const [periResults, setPeriResults] = useState([]);
  const [billResults, setBillResults] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState('periResults');
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (event) => {
    setFileSelected(event.target.files.length > 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = await fetch(`${apiUrl}/api/pronote/perisco`, {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error?.message || 'An error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);
      return;
    }
    const data = await response.json();
    setPeriResults(data);
    await generateBill(data);
  };

  const generateBill = async (periResults) => {
    const response = await fetch(`${apiUrl}/api/bleez/bill?format=json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ perisco: periResults })
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error?.message || 'An error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);
      return;
    }
    const data = await response.json();
    setBillResults(data);
  };

  const downloadCSV = async () => {

    const response = await fetch(`${apiUrl}/api/bleez/bill?format=csv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ perisco: periResults })
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error?.message || 'An error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);
      return;
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    const date = new Date().toLocaleDateString('fr-FR').split('/').reverse().join('-');
    a.download = `bleez_bill_${date}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleSubTabChange = (event, newValue) => {
    setActiveSubTab(newValue);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      method="POST"
      mt={3}>
      <Typography variant="h6" component="label" htmlFor="perisco">
        Conversion du périscolaire Pronote en facturation Bleez
      </Typography>
      <TextField
        type="file"
        accept=".txt"
        id="perisco"
        name="perisco"
        required
        fullWidth
        margin="normal"
        onChange={handleFileChange}
        slotProps={{
          inputLabel: { shrink: true }
        }}
      />
      <Box mt={2}>
        <Button variant="contained" color="primary" type="submit" disabled={!fileSelected}>
          Convertir
        </Button>
        {periResults.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadCSV}
            style={{ marginLeft: '10px' }}>
            Télécharger CSV
          </Button>
        )}
      </Box>
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {periResults.length > 0 && (
        <Box mt={3}>
          <Tabs value={activeSubTab} onChange={handleSubTabChange} aria-label="sub tabs example">
            <Tab label="Périscolaire" value="periResults" />
            <Tab label="Factures" value="billResults" />
          </Tabs>
          <Box mt={2}>
            {activeSubTab === 'periResults' && <PronotePeriscoTable data={periResults} />}
            {activeSubTab === 'billResults' && <BleezBillsTable data={billResults} />}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default PronotePerisco;
