import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

function BleezUsersTable({ data }) {
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);

  const handleRowClick = (index) => {
    setSelectedUserIndex(selectedUserIndex === index ? null : index);
  };

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 300px)">
      <TableContainer component={Paper} style={{ flexGrow: 1, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Login</TableCell>
              <TableCell>Account Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.login}</TableCell>
                  <TableCell>{row.account_number}</TableCell>
                </TableRow>
                {selectedUserIndex === index && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box p={2} border={1} borderColor="grey.400">
                        <Typography variant="h6">User Details</Typography>
                        <Typography>
                          <strong>Language:</strong> {row.language}
                        </Typography>
                        <Typography>
                          <strong>Contact:</strong> {row.contact}
                        </Typography>
                        <Typography>
                          <strong>Account Name:</strong> {row.account_name}
                        </Typography>
                        <Typography>
                          <strong>Devise:</strong> {row.devise}
                        </Typography>
                        <Typography>
                          <strong>Address:</strong> {row.address1}
                        </Typography>
                        <Typography>
                          <strong>Zip:</strong> {row.zip}
                        </Typography>
                        <Typography>
                          <strong>City:</strong> {row.city}
                        </Typography>
                        <Typography>
                          <strong>Country:</strong> {row.country}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

BleezUsersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      login: PropTypes.string.isRequired,
      account_number: PropTypes.string.isRequired,
      language: PropTypes.string,
      contact: PropTypes.string,
      account_name: PropTypes.string,
      devise: PropTypes.string,
      address1: PropTypes.string,
      zip: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string
    })
  ).isRequired
};
export default BleezUsersTable;
