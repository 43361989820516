import React, { useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import BleezUsersTable from './BleezUsersTable';

function BleezUsers() {
  const [results, setResults] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    setFileSelected(event.target.files.length > 0);
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = await fetch(`${apiUrl}/api/bleez/users?format=json`, {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error?.message || 'An error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);
      return;
    }
    const data = await response.json();
    setResults(data);
  };

  const downloadCSV = async () => {
    const response = await fetch(`${apiUrl}/api/bleez/users?format=csv`, {
      method: 'POST',
      body: new FormData(document.querySelector('form'))
    });
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error?.message || 'An error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);
      return;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    const date = new Date().toLocaleDateString('fr-FR').split('/').reverse().join('-');
    a.download = `bleez_users_${date}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
      method="POST"
      mt={3}>
      <Typography variant="h6" component="label" htmlFor="exp_eleve">
        Conversion des élèves Pronote en utilisateurs Bleez
      </Typography>
      <TextField
        type="file"
        accept=".csv"
        id="exp_eleve"
        name="export_eleves"
        required
        fullWidth
        margin="normal"
        onChange={handleFileChange}
        slotProps={{
          inputLabel: { shrink: true }
        }}
      />
      <Box mt={2}>
        <Button variant="contained" color="primary" type="submit" disabled={!fileSelected}>
          Convertir
        </Button>
        {results.length > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={downloadCSV}
            style={{ marginLeft: '10px' }}>
            Télécharger CSV
          </Button>
        )}
      </Box>
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      {results.length > 0 && <BleezUsersTable data={results} />}
    </Box>
  );
}

export default BleezUsers;
